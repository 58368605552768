<template>
  <div id="SendOrder">
    <div class="row">
      <div class="card_list">
        <div class="card">
          <div class="card_header">
            <span class="material-icons-two-tone"> category </span>
            <h4 class="sub_title_font">商品資訊</h4>
          </div>
          <div class="card_body info">
            <div class="input_group col_12 products">
              <label class="sub_content_font">詢問品項</label>
              <p
                class="content content_font"
                v-if="$store.state.products.length > 0"
              >
                <span
                  v-for="(item, item_index) in now_shop_cart"
                  :key="`shopcart_order${item}`"
                  >{{ GetProductTitle(item)
                  }}<i v-if="item_index != now_shop_cart.length - 1"
                    >、</i
                  ></span
                >
              </p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card_header">
            <span class="material-icons-two-tone"> supervisor_account </span>
            <h4 class="sub_title_font">聯絡資訊</h4>
            <p class="sub_content_font">必填資料*</p>
          </div>
          <div class="card_body">
            <div class="input_group col_6">
              <label class="sub_content_font">姓名*</label>
              <input
                type="text"
                :class="{ error: GetError('name') != '' }"
                v-model="form.name"
                name="name"
              />
              <p class="error">{{ GetError('name') }}</p>
            </div>
            <div class="input_group col_6">
              <label class="sub_content_font">電話*</label>
              <input
                type="text"
                :class="{ error: GetError('phone') != '' }"
                v-model="form.phone"
                name="phone"
              />
              <p class="error">{{ GetError('phone') }}</p>
            </div>
            <div class="input_group col_12">
              <label class="sub_content_font">信箱</label>
              <input type="text" v-model="form.email" name="email" />
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card_header">
            <span class="material-icons-two-tone"> insert_comment </span>
            <h4 class="sub_title_font">回饋訊息</h4>
          </div>
          <div class="card_body">
            <div class="input_group col_12">
              <label class="sub_content_font">訊息標題</label>
              <p class="value">商品詢價</p>
            </div>
            <div class="input_group col_12">
              <label class="sub_content_font">留言內容</label>
              <textarea v-model="form.comment" name="comment"></textarea>
            </div>
          </div>
        </div>
      </div>
      <button class="submit_btn content_font" @click="Validate">
        確定送出
      </button>
    </div>
  </div>
</template>

<script>
import DataValid from '../common/data_valid';
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'SendOrder',
  mixins: [DataValid],
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        title: '商品詢價',
        comment: '',
      },
      errors: [],
      meta_data: null,
    };
  },
  methods: {
    Validate() {
      this.errors = [];
      let valid_array = [
        {
          type: 'name',
          msg: this.validName(this.form.name),
        },
        {
          type: 'phone',
          msg: this.validPhone(this.form.phone),
        },
      ];

      valid_array.forEach((item) => {
        if (item.msg != true) {
          this.errors.push(item);
        }
      });

      if (this.errors.length <= 0 && this.now_shop_cart.length > 0) {
        this.SendOrder();
      }
    },
    async SendOrder() {
      this.$store.commit('SetLoading', true);
      let response = await this.SendData('/mail', 'post', {
        Data: {
          Name: this.form.name,
          Email: this.form.email,
          Phone: this.form.phone,
          Title: this.form.title,
          Content: this.form.comment,
          ProductID: this.now_shop_cart,
        },
      });
      if (response != 'error') {
        this.$store.commit('SetLoading', false);
        this.$store.commit('SetDialog', {
          status: true,
          content: '訊息已送出',
        });
      }
    },
    GetError(val) {
      let error = this.errors.filter((item) => item.type == val);
      if (error.length > 0) {
        return error[0].msg;
      } else {
        return '';
      }
    },
    GetProductTitle(id) {
      return this.$store.state.products.filter(
        (item) => item.ProductID == id
      )[0].Title;
    },
  },
  mounted() {
    window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData(
      '商品詢問',
      '若您對宏信五金行有任何的建議、購買前後疑問或任何商品問題，我們非常歡迎您能來信詢問。若您有急需反應之事項建議您致電客服專線，您將能獲得比較即時的客服服務。',
      ''
    );
  },
  computed: {
    now_shop_cart() {
      return this.$store.state.shop_cart;
    },
  },

  metaInfo() {
    return this.meta_data;
  },
};
</script>
